import * as React from 'react';
import {
  motion,
  AnimatePresence,
} from 'framer-motion';

import {
  Colors,
  Breakpoint,
  Lang,

  useDimensions,

  MainContainer,

  Text,
  Icon,
  Button,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/setup';

export default function FormSuccessMessage(props) {
  const size = useDimensions();
  const { _, i18n } = Lang.useTranslation();

  const lang = Setup.PSEUDO ? 'en' : i18n.language;

  return (
    <AnimatePresence>
      {
        props.visible && (
          <MainContainer>
            <motion.div
              style={{
                height: 'auto',
                width: size.width < 390 ? 'calc(100% - 30px)' : 350,
                backgroundColor: Colors.WHITE,
                border: `solid 1px ${Colors.MEDIUM_WHITE}`,
                borderRadius: 15,
                position: 'fixed',
                zIndex: 100,
                bottom: size.width < Breakpoint.lg ? 95 : 15,
                WebkitBoxShadow: `0px 3px 15px 2px ${Colors.BLACK}40`,
                boxShadow: `0px 3px 15px 2px ${Colors.BLACK}40`,
                paddingLeft: 15,
                paddingRight: 15,
                paddingBottom: 15,
                paddingTop: 10,
              }}
              initial={{
                opacity: 0,
                right: -400,
              }}
              animate={{
                opacity: 1,
                right: 15,
              }}
              exit={{
                opacity: 0,
                rigth: -400,
              }}
              transition={{
                delay: 0,
                duration: 0.3,
              }}
            >
              <div
                style={{
                  height: 80,
                  width: '100%',
                  marginBottom: 15,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    height: 70,
                    width: 70,
                    borderRadius: 50,
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: `solid 4px ${Colors.MEDIUM_WHITE}`,
                  }}
                >
                  <Icon
                    name="Check"
                    width={30}
                    height={30}
                    color={Colors.GREEN}
                    iconSet="FA"
                  />
                </div>
              </div>
              <Text
                textAlign="center"
                fontWeight={500}
                fontSize="1.5em"
              >
                {props.content.title[lang]}
              </Text>
              <div
                style={{
                  marginTop: 15,
                  marginBottom: 45,
                }}
              >
                <Text
                  textAlign="center"
                  portableText
                >
                  {props.content.text[lang]}
                </Text>
              </div>
              <Button
                id="Button_FormSuccessMessageClose"
                text={_('close', { ns: 'ui' })}
                colorScheme="green"
                onClick={props.onClose}
              />
            </motion.div>
          </MainContainer>
        )
      }
    </AnimatePresence>
  );
}
