import * as React from 'react';

import {
  Colors,
  Breakpoint,
  Lang,

  useDimensions,

  ContentContainer,
  Container,

  Image,
  TextBlock,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/setup';

export default function Mission(props) {
  const { i18n } = Lang.useTranslation();
  const size = useDimensions();

  const lang = Setup.PSEUDO ? 'en' : i18n.language;

  return (
    <>
      <div
        id="mission"
      />
      <section
        className="SectionText"
        style={{
          marginTop: 100,
        }}
      >
        <ContentContainer>
          <Container
            share={[50, 50]}
            breakpoint={830}
          >
            {
            size.width >= 830 && (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  paddingRight: size.width < Breakpoint.sm ? 0 : 7.5,
                }}
              >
                <Image
                  src="https://cdn.sanity.io/images/pnajo2cq/production/c08de3a8d6d1bbad418baeb5ea29446ad5c2c1c3-1000x800.jpg"
                  confetti={{
                    type: 'Figure',
                    height: 140,
                    width: 140,
                    color: Colors.YELLOW,
                    position: 'absolute',
                    right: 50,
                    bottom: 0,
                  }}
                  wrapper="Figure"
                  id="MissionImage"
                />
              </div>
            )
          }
            <div
              style={{
                height: '100%',
                width: (size.width >= Breakpoint.lg && size.width < Breakpoint.xxl) ? '80%' : '100%',
                paddingLeft: size.width < Breakpoint.sm ? 0 : 7.5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <TextBlock
                title={{
                  content: props.content.title[lang],
                }}
                text={{
                  content: props.content.text[lang],
                  portableText: true,
                }}
                align={size.width < 830 ? 'center' : 'left'}
              />
            </div>
          </Container>
        </ContentContainer>
      </section>
    </>
  );
}
