import * as React from 'react';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';

import {
  Colors,
  Icon,
  Lang,
  Breakpoint,

  useDimensions,

  Text,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/setup';

export default function FaqItem(props) {
  const { i18n } = Lang.useTranslation();
  const size = useDimensions();

  const lang = Setup.PSEUDO ? 'en' : i18n.language;

  /* eslint-disable-next-line no-underscore-dangle */
  const isActive = props.active === props.data._id;

  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        backgroundColor: Colors.LIGHT_WHITE,
        borderRadius: 10,
        overflow: 'hidden',
      }}
    >
      <button
        type="button"
        className="Hover Button_FaqItem"
        /* eslint-disable-next-line no-underscore-dangle */
        onClick={() => props.setActive(isActive ? false : props.data._id)}
        style={{
          paddingLeft: 15,
          paddingRight: 15,
          paddingTop: 5,
          paddingBottom: 5,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: 60,
          height: 'auto',
          width: '100%',
          border: 'solid 0px transparent',
          borderRadius: 10,
          backgroundColor: Colors.LIGHT_WHITE,
          fontSize: '1em',
        }}
      >
        <div
          style={{
            width: 'calc(100% - 60px)',
          }}
        >
          <Text
            fontWeight={500}
            portableText={props.data.question[lang] !== undefined}
          >
            {props.data.question[lang] && props.data.question[lang]}
          </Text>
        </div>
        <div
          style={{
            height: size.width < Breakpoint.sm ? 30 : 60,
            width: size.width < Breakpoint.sm ? 30 : 60,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              height: 30,
              width: 30,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              transform: `rotate(${isActive ? '45' : '0'}deg)`,
              transition: 'transform .2s ease-in-out',
            }}
          >
            <Icon
              name="Plus"
              width={30}
              height={30}
              color={Colors.BLUE}
              iconSet="FA"
            />
          </div>
        </div>
      </button>
      <AnimatePresence>
        {
          isActive && (
            <motion.div
              style={{
                paddingLeft: 15,
                paddingRight: 15,
              }}
              initial={{
                height: 0,
              }}
              animate={{
                height: 'auto',
              }}
              exit={{
                height: 0,
              }}
            >
              <div
                style={{
                  borderTop: `solid 2px ${Colors.MEDIUM_WHITE}`,
                  height: '100%',
                  paddingTop: 15,
                  paddingBottom: 15,
                }}
              >
                <Text
                  portableText={props.data.answer[lang] !== undefined}
                  fontWeight={size.width < Breakpoint.sm ? 300 : 100}
                  lineHeight={1.3}
                >
                  {
                    props.data.answer[lang] && props.data.answer[lang]
                  }
                </Text>
              </div>
            </motion.div>
          )
        }
      </AnimatePresence>
    </div>
  );
}
