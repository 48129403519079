import * as React from 'react';

import {
  Colors,
  Lang,

  Confetti,
  filterBasedOnId,
  LargeContainer,
  useDimensions,
} from '@bluecurrent/web-component-lib';

import Setup from '../config/setup';

import Id from '../lib/variables';
import {
  companyDataQueryItems,
  dataQueryItems,
  faqQueryItems,
  phrasesQueryItems,
  sectionsQueryItems,
  testimonialsQueryItems,
} from '../lib/queries';
import { getClient, overlayDrafts } from '../lib/sanity.server';

import Landing from '../components/Content/Landing';
import Mission from '../components/Content/Mission';
import Why from '../components/Content/Why';
import Video from '../components/Content/Video';
import How from '../components/Content/How';
import DownloadPlan from '../components/Content/DownloadPlan';
import Testimonials from '../components/Content/Testimonials';
import ReserveForm from '../components/Content/ReserveForm';
import InvestForm from '../components/Content/InvestForm';
import Faq from '../components/Content/Faq';
import Contact from '../components/Content/Contact';

export default function Index({ data, faqData, testimonialsData }) {
  const { i18n } = Lang.useTranslation();
  const size = useDimensions();

  const lang = Setup.PSEUDO ? 'en' : i18n.language;

  const [
    investEmail,
    investPhone,
    phraseTheFutureIsMobility,
    phraseBecomeCoOwner,
    phraseSustainableInMotion,
    phrase2035IsTravelElectric,
    phraseRevenueGrowthAchieved,
    phraseInnovationMarketLeader,
    phraseHardwareWithOwnPlatform,
    phraseInvestInSustainableFuture,
    phraseTargetTenfoldInThreeYears,
    phraseWhyInvest,
    phraseMoreInformationAboutInvesting,
    phrasePleaseContactUs,
    phraseQuickSelect,
    phraseScheduleMeeting,
    sectionInvestInSustainableFuture,
    sectionSustainability,
    sectionInnovation,
    sectionCustomerHappiness,
    sectionDownloadPitchDeck,
    sectionPartnersRespect,
    sectionDontWaitReserveInSustainable,
    sectionDontWaitInvestInSustainable,
    sectionReserveSuccessMessage,
    sectionInvestSuccessMessage,
    sectionSuccessMessagePlan,
    sectionSuccessInvestRound,
    sectionSignUpSuccessMessage,
    dataAmount,
    dataLastUpdate,
  ] = filterBasedOnId(
    data,
    Id.Invest_Email,
    Id.Invest_Phone,
    Id.Phrase_The_Future_Is_Mobility,
    Id.Phrase_Become_CoOwner,
    Id.Phrase_Sustainable_In_Motion,
    Id.Phrase_2035_Is_Travel_Electric,
    Id.Phrase_Revenue_Growth_Achieved,
    Id.Phrase_Innovation_Market_Leader,
    Id.Phrase_Hardware_With_Own_Platform,
    Id.Phrase_Invest_In_Sustainable_Future,
    Id.Phrase_Target_Tenfold_In_Three_Years,
    Id.Phrase_Why_Invest,
    Id.Phrase_More_Information_About_Investing,
    Id.Phrase_Please_Contact_Us,
    Id.Phrase_Quick_Select,
    Id.Phrase_Schedule_Meeting,
    Id.Section_Invest_In_Sustainable_Future,
    Id.Section_Sustainability,
    Id.Section_Innovation,
    Id.Section_Customer_Happiness,
    Id.Section_Download_Pitch_Deck,
    Id.Section_Partners_Respect,
    Id.Section_Dont_Wait_Reserve_In_Sustainable,
    Id.Section_Dont_Wait_Invest_In_Sustainable,
    Id.Section_Reserve_Success_Message,
    Id.Section_Invest_Success_Message,
    Id.Section_Success_Message_Plan,
    Id.Section_Success_Invest_Round,
    Id.Section_Success_SignUp_Message,
    Id.Data_Amount,
    Id.Data_Last_Update,
  );

  return (
    <>
      <Landing
        title={phraseTheFutureIsMobility.text[lang]}
        text={phraseBecomeCoOwner.text[lang]}
        amount={dataAmount.text}
        lastUpdate={dataLastUpdate.text}
      />
      {
        !Setup.SUCCESS && size.width >= 830 && (
          <LargeContainer>
            <div
              style={{
                height: 'auto',
                width: 'auto',
                position: 'absolute',
                zIndex: -1,
              }}
            >
              <Confetti
                type="DotsPath"
                height={1000}
                width={1000}
                position="absolute"
                color={Colors.MEDIUM_WHITE}
                top={size.width < 600 ? -400 : -300}
                left={size.width < 1300 ? -250 : -50}
                transform="scaleX(-1) rotate(15deg)"
              />
            </div>
          </LargeContainer>
        )
      }
      {
        !Setup.SUCCESS && (
          <Mission
            content={sectionInvestInSustainableFuture}
          />
        )
      }
      {
        !Setup.SUCCESS && (
          <Why
            title={phraseWhyInvest.text[lang]}
            data={[
              {
                id: 0,
                title: {
                  content: phraseRevenueGrowthAchieved.text[lang],
                  portableText: true,
                },
                text: {
                  portableText: false,
                },
                icon: {
                  name: 'Graph',
                  height: 100,
                  width: 100,
                  color: Colors.GREY,
                },
              },
              {
                id: 1,
                title: {
                  content: phraseInvestInSustainableFuture.text[lang],
                  portableText: true,
                },
                text: {
                  portableText: false,
                },
                icon: {
                  name: 'Earth',
                  height: 130,
                  width: 130,
                  color: Colors.GREY,
                },
              },
              {
                id: 2,
                title: {
                  content: phraseHardwareWithOwnPlatform.text[lang],
                  portableText: true,
                },
                text: {
                  portableText: false,
                },
                icon: {
                  name: 'App',
                  height: 100,
                  width: 100,
                  color: Colors.GREY,
                },
              },
              {
                id: 3,
                title: {
                  content: phrase2035IsTravelElectric.text[lang],
                  portableText: true,
                },
                text: {
                  portableText: false,
                },
                icon: {
                  name: 'CarHouse',
                  height: 130,
                  width: 130,
                  color: Colors.GREY,
                },
              },
              {
                id: 4,
                title: {
                  content: phraseInnovationMarketLeader.text[lang],
                  portableText: true,
                },
                text: {
                  portableText: false,
                },
                icon: {
                  name: 'LightBulb',
                  height: 100,
                  width: 100,
                  color: Colors.GREY,
                },
              },
              {
                id: 5,
                title: {
                  content: phraseTargetTenfoldInThreeYears.text[lang],
                  portableText: true,
                },
                text: {
                  portableText: false,
                },
                icon: {
                  name: 'Battery',
                  height: 110,
                  width: 110,
                  color: Colors.GREY,
                  colorBattery: Colors.GREEN,
                },
              },
            ]}
          />
        )
      }
      {
        !Setup.SUCCESS && (
          <How
            data={[
              {
                id: Id.Section_Sustainability,
                content: sectionSustainability,
              },
              {
                id: Id.Section_Innovation,
                content: sectionInnovation,
              },
              {
                id: Id.Section_Customer_Happiness,
                content: sectionCustomerHappiness,
              },
            ]}
          />
        )
      }
      {
        !Setup.SUCCESS && (
          <Video
            title={phraseSustainableInMotion.text[lang]}
          />
        )
      }
      {
        !Setup.SUCCESS && (
          <DownloadPlan
            content={sectionDownloadPitchDeck}
            successContent={sectionSuccessMessagePlan}
          />
        )
      }
      {
        !Setup.SUCCESS && (
          <Testimonials
            content={sectionPartnersRespect}
            data={testimonialsData}
          />
        )
      }
      {
        Setup.RESERVE ? (
          <ReserveForm
            content={
              Setup.SUCCESS
                ? sectionSuccessInvestRound
                : sectionDontWaitReserveInSustainable
            }
            quickSelectText={phraseQuickSelect.text[lang]}
            successContent={sectionReserveSuccessMessage}
          />
        ) : (
          <InvestForm
            content={
              Setup.SUCCESS
                ? sectionSuccessInvestRound
                : sectionDontWaitInvestInSustainable
            }
            quickSelectText={phraseQuickSelect.text[lang]}
            successContent={
              Setup.SUCCESS
                ? sectionSignUpSuccessMessage
                : sectionInvestSuccessMessage
            }
          />
        )
      }
      <Contact
        topLeft={{
          text: phraseMoreInformationAboutInvesting.text[lang],
          href: false,
          portableText: true,
        }}
        bottomLeft={{
          text: phrasePleaseContactUs.text[lang],
          href: false,
          portableText: true,
        }}
        topRight={{
          text: investEmail.text[lang],
          href: `mailto:${lang === 'en' ? 'invest@bluecurrent.nl' : 'investeren@bluecurrent.nl'}`,
          portableText: true,
        }}
        bottomRight={{
          text: investPhone.text[lang],
          href: 'tel:085-8880386',
          portableText: true,
        }}
        buttons={Setup.MEETING && [
          {
            id: 0,
            text: phraseScheduleMeeting.text[lang],
            onClick: () => window.open('https://meetings.bluecurrent.nl/investeren'),
            colorScheme: 'green',
          },
        ]}
      />
      {
        !Setup.SUCCESS && (
          <Faq
            data={faqData}
          />
        )
      }
    </>
  );
}

const dataQuery = () => `
  [
    ...*[
      _type == "investmentCampaignSections" && _id in
      [
        "${Id.Section_Invest_In_Sustainable_Future}",
        "${Id.Section_Sustainability}",
        "${Id.Section_Innovation}",
        "${Id.Section_Customer_Happiness}",
        "${Id.Section_Download_Pitch_Deck}",
        "${Id.Section_Partners_Respect}",
        "${Id.Section_Dont_Wait_Reserve_In_Sustainable}",
        "${Id.Section_Dont_Wait_Invest_In_Sustainable}",
        "${Id.Section_Reserve_Success_Message}",
        "${Id.Section_Invest_Success_Message}",
        "${Id.Section_Success_Message_Plan}",
        "${Id.Section_Success_Invest_Round}",
        "${Id.Section_Success_SignUp_Message}",
      ]
    ] {
      ${sectionsQueryItems}
    },
    ...*[
      _type == "investmentCampaignPhrases" && _id in
      [
        "${Id.Phrase_The_Future_Is_Mobility}",
        "${Id.Phrase_Become_CoOwner}",
        "${Id.Phrase_Sustainable_In_Motion}",
        "${Id.Phrase_2035_Is_Travel_Electric}",
        "${Id.Phrase_Revenue_Growth_Achieved}",
        "${Id.Phrase_Innovation_Market_Leader}",
        "${Id.Phrase_Hardware_With_Own_Platform}",
        "${Id.Phrase_Invest_In_Sustainable_Future}",
        "${Id.Phrase_Target_Tenfold_In_Three_Years}",
        "${Id.Phrase_Why_Invest}",
        "${Id.Phrase_More_Information_About_Investing}",
        "${Id.Phrase_Please_Contact_Us}",
        "${Id.Phrase_Quick_Select}",
        "${Id.Phrase_Schedule_Meeting}",
      ]
    ] {
      ${phrasesQueryItems}
    },
    ...*[
      _type == "investmentCampaignCompanyData" && _id in
      [
        "${Id.Invest_Email}",
        "${Id.Invest_Phone}",
      ]
    ] {
      ${companyDataQueryItems}
    },
    ...*[
      _type == "investmentCampaignData" && _id in
      [
        "${Id.Data_Amount}",
        "${Id.Data_Last_Update}",
      ]
    ] {
      ${dataQueryItems}
    },
  ]
`;

const faqQuery = () => `
  *[
    _type == "investmentCampaignFAQ"
  ] {
    ${faqQueryItems}
  }
  |
  order(orderRank)
 `;

const testimonialsQuery = () => `
  *[
    _type == "investmentCampaignTestimonials"
  ] {
    ${testimonialsQueryItems}
  }
  |
  order(orderRank)
 `;

export async function getStaticProps({ preview = false }) {
  const data = overlayDrafts(await getClient(preview).fetch(dataQuery()));
  const faqData = overlayDrafts(await getClient(preview).fetch(faqQuery()));
  const testimonialsData = overlayDrafts(await getClient(preview).fetch(testimonialsQuery()));
  return {
    props: {
      data,
      faqData,
      testimonialsData,
      preview,
    },
    revalidate: 300,
  };
}
