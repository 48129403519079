import * as React from 'react';

import {
  Lang,
  Colors,
  Breakpoint,

  useDimensions,

  Container,

  CustomIcon,
  Icon,
  Text,
  TextBlock,
  Carousel,
  Arrow,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/setup';

import TestimonialMobile from '../Sections/TestimonialMobile';

export default function Testimonials(props) {
  const { _, i18n } = Lang.useTranslation();
  const size = useDimensions();

  const lang = Setup.PSEUDO ? 'en' : i18n.language;

  const carouselRef = React.useRef(0);

  const [currentItem, setCurrentItem] = React.useState(0);
  const [viewAll, setViewAll] = React.useState(false);

  const responsive = () => {
    if (size.width < Breakpoint.xs) return 1;
    return 2;
  };

  return (
    <section
      className="SectionTestimonials"
      style={{
        marginTop: 150,
        position: 'relative',
        zIndex: 0,
      }}
    >
      <div
        style={{
          width: size.width < Breakpoint.xxl ? '94%' : '65%',
          maxWidth: 1400,
          marginLeft:
            size.width < Breakpoint.xxl
              ? '3%'
              : size.width < Breakpoint.max
                ? '22.5%'
                : 'auto',
          marginRight:
            size.width < Breakpoint.xxl
              ? '3%'
              : size.width < Breakpoint.max
                ? '12.5%'
                : 'auto',
          height: 'auto',
        }}
      >
        <Container
          share={[40, 60]}
          breakpoint={900}
        >
          <div
            style={{
              height: 'auto',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              paddingRight: 30,
            }}
          >
            <div
              style={{
                width: 90,
                height: 90,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CustomIcon
                name="Heart"
                height={76.9}
                width={90}
                color={Colors.GREY}
              />
            </div>
            <TextBlock
              title={{
                content: props.content.title[lang],
              }}
              text={{
                content: props.content.text[lang],
                portableText: true,
              }}
              align="left"
            />
            {
              size.width >= Breakpoint.fd && (
                <div
                  style={{
                    marginTop: -45,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Arrow
                    type="Curved"
                    transform={`scaleX(${size.width < 900 ? '1' : '-1'}) rotate(${size.width < Breakpoint.sm ? -20 : 10}deg)`}
                    height={150}
                    width={150}
                  />
                </div>
              )
            }
          </div>
          {
            size.width < Breakpoint.xs ? (
              <div>
                {
                  !viewAll && (
                    <TestimonialMobile
                      data={props.data[0]}
                    />
                  )
                }
                {
                  viewAll && props.data.map((e) => (
                    <div
                      style={{
                        marginBottom: 15,
                      }}
                    >
                      <TestimonialMobile
                        /* eslint-disable-next-line no-underscore-dangle */
                        key={e._id}
                        data={e}
                      />
                    </div>
                  ))
                }
                <div
                  style={{
                    width: 'auto',
                    marginTop: 15,
                  }}
                >
                  <button
                    type="button"
                    id="Button_ViewAllLess"
                    className="Hover"
                    onClick={() => {
                      setViewAll(!viewAll);
                    }}
                    style={{
                      backgroundColor: 'transparent',
                      fontSize: '1em',
                      border: 'solid 0px transparent',
                    }}
                  >
                    <Text
                      color={Colors.BLUE}
                    >
                      {_(viewAll ? 'viewLess' : 'viewAll', { ns: 'ui' })}
                    </Text>
                  </button>
                </div>
              </div>
            ) : (
              <div
                ref={carouselRef}
                style={{
                  height: size.width < Breakpoint.sm ? 500 : '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                <Carousel
                  height={500}
                  width="100%"
                  maxItems={responsive()}
                  colorScheme="white"
                  fade
                  visibleArrows
                  disable={false}
                  toEnd
                  onChangeActive={(active) => setCurrentItem(active)}
                >
                  {
                    props.data.map((e, i) => (
                      <div
                        /* eslint-disable-next-line no-underscore-dangle */
                        key={e._id}
                        style={{
                          height: '100%',
                          width: '100%',
                          paddingLeft: size.width < 900 ? 0 : 48,
                          paddingRight: size.width < Breakpoint.sm
                            ? 48
                            : 24,
                        }}
                      >
                        <div
                          style={{
                            height: '100%',
                            width: '100%',
                            backgroundColor: Colors.WHITE,
                            borderRadius: 15,
                            border: `solid 2px ${Colors.MEDIUM_WHITE}`,
                            paddingLeft: 30,
                            paddingBottom: 30,
                            paddingRight: 30,
                            paddingTop: 30,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            transform: `scale(${currentItem === i ? '1' : '0.9'})`,
                            transition: 'all .2s linear',
                          }}
                        >
                          <Text
                            fontSize="1.3em"
                            fontWeight={100}
                            color={currentItem === i ? Colors.GREY : Colors.MEDIUM_GREY}
                            lineHeight={1.25}
                            portableText
                          >
                            {e.message[lang]}
                          </Text>
                          <div>
                            <div
                              style={{
                                width: 100,
                                height: 100,
                                opacity: currentItem === i ? 1 : 0.5,
                                marginBottom: 15,
                              }}
                            >
                              <img
                                src={e.image}
                                width={100}
                                height={100}
                                alt={e.name}
                                style={{
                                  borderRadius: 100,
                                }}
                              />
                            </div>
                            <Text
                              fontWeight={500}
                              color={currentItem === i ? Colors.GREY : Colors.MEDIUM_GREY}
                            >
                              {e.name}
                            </Text>
                            <div
                              style={{
                                paddingTop: 5,
                              }}
                            >
                              <Text
                                color={currentItem === i ? Colors.GREY : Colors.MEDIUM_GREY}
                                portableText
                              >
                                {e.function[lang]}
                              </Text>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </Carousel>
                <div
                  style={{
                    height: 45,
                    width: '100%',
                    marginTop: 20,
                    paddingLeft: size.width < 900 ? 0 : 48,
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {
                    props.data.map((e, i) => (
                      <div
                        /* eslint-disable-next-line no-underscore-dangle */
                        key={e._id}
                        style={{
                          height: 45,
                          width: 45,
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          transform: `scale(${currentItem === i ? '1' : '0.6'})`,
                          transition: 'transform .2s easeInOut',
                        }}
                      >
                        <Icon
                          name="Heart"
                          height={30}
                          width={30}
                          color={currentItem === i ? Colors.ORANGE : Colors.MEDIUM_WHITE}
                          iconSet="FA"
                          type={currentItem === i ? 'light' : 'solid'}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            )
          }
        </Container>
      </div>
    </section>
  );
}
