import * as React from 'react';

import {
  Colors,
  Breakpoint,
  Lang,
  Confetti,

  useDimensions,

  ContentContainer,
  LargeContainer,

  TextBlock,
  Form,
  Loader,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/setup';

import FormSuccessMessage from '../Sections/FormSuccessMessage';

export default function Invest(props) {
  const { i18n } = Lang.useTranslation();
  const size = useDimensions();

  const lang = Setup.PSEUDO ? 'en' : i18n.language;

  const contentContainerRef = React.useRef(0);

  const [width, setWidth] = React.useState('100%');
  const [success, setSuccess] = React.useState(false);

  const getWidth = () => {
    if (contentContainerRef.current?.clientWidth) {
      setWidth(contentContainerRef.current.clientWidth);
    }
  };

  React.useEffect(() => {
    getWidth();
  }, [width]);

  React.useEffect(() => {
    window.addEventListener('resize', getWidth);

    return () => {
      window.removeEventListener('resize', getWidth);
    };
  }, []);

  return (
    <>
      {
        size.width >= Breakpoint.xs && (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              zIndex: -1,
              marginTop: size.width < Breakpoint.xxl
                ? size.width < 900
                  ? -100
                  : -275
                : -450,
              overflow: 'hidden',
            }}
          >
            <Confetti
              type="DotsPath"
              height={1000}
              width={1000}
              color={Colors.MEDIUM_WHITE}
              position="absolute"
              zIndex={-1}
              transform={`rotate(${size.width < 900 ? 100 : 0}deg)`}
              top={0}
              left={size.width < 900 ? -300 : -50}
            />
          </div>
        )
      }
      <section
        className="SectionForm"
        id="invest"
        style={{
          marginTop: 150,
          position: 'relative',
          zIndex: 1,
          overflow: 'hidden',
        }}
      >
        <LargeContainer>
          <div
            style={{
              backgroundColor: Colors.BLUE,
              height: 'auto',
              width: '100%',
              paddingTop: 60,
              paddingBottom: 60,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: size.width < 1440 ? 0 : 15,
              positon: 'relative',
            }}
          >
            <div
              style={{
                width: size.width >= Breakpoint.md && size.width < 1440 ? '70%' : width,
                backgroundColor: Colors.WHITE,
                paddingTop: size.width < Breakpoint.sm ? 30 : 50,
                paddingBottom: size.width < Breakpoint.sm ? 30 : 50,
                paddingLeft: size.width < Breakpoint.sm ? 15 : '8%',
                paddingRight: size.width < Breakpoint.sm ? 15 : '8%',
                borderRadius: 15,
                position: 'relative',
                zIndex: 1,
              }}
            >
              <div
                style={{
                  marginBottom: 20,
                }}
              >
                <TextBlock
                  title={{
                    content: props.content.title[lang],
                  }}
                  text={{
                    content: props.content.text[lang],
                    portableText: true,
                  }}
                  align="center"
                />
              </div>
              <Form
                type="Hubspot"
                portalId="26281745"
                formId={i18n.language === 'en' ? '28c6e46b-fa19-4837-9451-e73cd092e6ec' : '3e5340e3-fa22-4b27-9a32-396123f6b515'}
                onSubmit={() => setSuccess(true)}
                loading={(
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Loader />
                  </div>
                )}
              />
            </div>
            {
              size.width >= Breakpoint.xs && (
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    marginTop: size.width < Breakpoint.xxl
                      ? size.width < 630 ? 650 : 500
                      : 200,
                    zIndex: 0,
                    overflow: 'hidden',
                  }}
                >
                  <Confetti
                    type="DotsPath"
                    height={1000}
                    width={1000}
                    color={Colors.WHITE}
                    position="absolute"
                    transform="scaleX(-1) rotate(20deg)"
                    right={size.width < Breakpoint.xxl ? -200 : -110}
                    opacity={0.5}
                  />
                </div>
              )
            }
          </div>
        </LargeContainer>
        <ContentContainer>
          <div
            ref={contentContainerRef}
            style={{
              width: '100%',
              height: 0,
            }}
          />
        </ContentContainer>
      </section>
      {
        success && (
          <FormSuccessMessage
            visible={success}
            onClose={() => setSuccess(false)}
            content={props.successContent}
          />
        )
      }
    </>
  );
}
