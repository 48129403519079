import * as React from 'react';

import {
  Lang,
  Colors,

  Text,
} from '@bluecurrent/web-component-lib';

export default function ByPressingYouAcceptText(props) {
  const { _ } = Lang.useTranslation();

  return (
    <div
      className="Link"
      style={{
        marginTop: 7.5,
        width: 'auto',
      }}
    >
      <Text
        fontSize="1em"
        color={Colors.DARK_GREY}
        fontWeight={100}
      >
        {_('byPressingYouAccept', {
          ns: 'legal',
          button: props.button,
          link: props.link,
          href: props.href,
        })}
      </Text>
    </div>
  );
}
