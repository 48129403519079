import * as React from 'react';

import {
  Colors,
  Lang,

  Text,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/setup';

export default function TestimonialMobile(props) {
  const { i18n } = Lang.useTranslation();

  const lang = Setup.PSEUDO ? 'en' : i18n.language;

  return (
    <div
      style={{
        width: '100%',
        height: 'auto',
        border: `solid 2px ${Colors.MEDIUM_WHITE}`,
        padding: 15,
        borderRadius: 10,
      }}
    >
      <Text
        fontSize="1.3em"
        lineHeight={1.3}
        portableText
      >
        {props.data.message[lang]}
      </Text>

      <div
        style={{
          width: 100,
          height: 100,
          marginTop: 15,
          marginBottom: 15,
        }}
      >
        <img
          src={props.data.image}
          width={100}
          height={100}
          alt={props.data.name}
          style={{
            borderRadius: 100,
          }}
        />
      </div>
      <Text
        fontWeight={500}
      >
        {props.data.name}
      </Text>
      <div
        style={{
          paddingTop: 5,
        }}
      >
        <Text
          portableText
        >
          {props.data.function[lang]}
        </Text>
      </div>
    </div>
  );
}
