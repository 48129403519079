import * as React from 'react';

import {
  Lang,
  Colors,
  Breakpoint,

  currencyFormat,
  counter,
  useDimensions,

  Text,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/setup';

export default function hasAlreadyReserved(props) {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();

  return (
    <>
      <div
        style={{
          height: 'auto',
          width: '100%',
          backgroundColor: Colors.BLUE,
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: size.width < 830 ? '3%' : '8%',
          paddingRight: size.width < 830 ? '3%' : '8%',
          borderBottomLeftRadius: size.width < Breakpoint.xxl ? 0 : 10,
          borderBottomRightRadius: size.width < Breakpoint.xxl ? 0 : 10,
        }}
      >
        <Text
          fontSize="1.9em"
          fontWeight={500}
          textAlign="center"
          color={Colors.WHITE}
        >
          {
            Setup.SUCCESS
              ? _('invest.success', { amount: currencyFormat(counter(0, parseInt(2500000, 10), 2), 'nl', 'EUR'), ns: 'branding' })
              : _('invest.hasAlreadyReserved', { amount: currencyFormat(counter(0, parseInt(props.amount, 10), 2), 'nl', 'EUR'), ns: 'branding' })
            }
        </Text>
      </div>
      {
        !Setup.SUCCESS && (
          <div
            style={{
              height: 'auto',
              width: '100%',
              paddingRight: 15,
              paddingTop: size.width < 830 ? 0 : 5,
            }}
          >
            <Text
              fontSize="1em"
              textAlign="right"
              color={size.width < 830 ? Colors.WHITE : Colors.DARK_WHITE}
              fontWeight={100}
            >
              {`${_('lastUpdate', { ns: 'ui' })}: ${props.lastUpdate}`}
            </Text>
          </div>
        )
      }
    </>
  );
}
