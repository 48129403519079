const Id = {
  // Sections
  Section_Invest_In_Sustainable_Future: '901c80f3-3af7-4a5a-a24c-a616b523bd77',
  Section_Sustainability: 'aad45814-a024-40df-81bf-c784c12327ba',
  Section_Innovation: 'b917c575-27de-4b3f-85c2-21b5ace6a41f',
  Section_Customer_Happiness: '62762f3f-ed4a-45e0-8655-980ccdebef4c',
  Section_Download_Pitch_Deck: '0588d827-3ffa-4cd7-9d66-d18383deccf0',
  Section_Partners_Respect: '489959d8-bf4b-49b7-bace-f178f791124f',
  Section_Dont_Wait_Reserve_In_Sustainable: '59985393-e4f2-4a56-a8c1-43cb9086aec4',
  Section_Dont_Wait_Invest_In_Sustainable: 'cf6e5b23-c600-48ac-b6a9-82d8d6dcb746',
  Section_Reserve_Success_Message: 'af858c0c-1718-4e58-b7d9-15c766ff47e7',
  Section_Invest_Success_Message: '696b6b36-6ddb-4da6-b6a8-577ac2b3a6f2',
  Section_Success_Message_Plan: '3f5c166a-023f-4566-b78b-cdb57880424f',
  Section_Success_Invest_Round: '00cc31fe-e819-4f82-bba8-6764ba89398b',
  Section_Success_SignUp_Message: '21036f82-5d76-4e4e-b33b-668fcabab7ba',
  // Phrases
  Phrase_Awesome_Still_Here: '5c61be3d-5cb9-4afb-8898-256d53669d9e',
  Phrase_The_Future_Is_Mobility: '184ac32c-7201-4aa9-bafe-a7b2e4053128',
  Phrase_Become_CoOwner: '739c2d6e-92c4-4e50-8f50-9cbd743c5a71',
  Phrase_Sustainable_In_Motion: 'c82a1c10-987e-4c44-b86c-b85da0ec0b1c',
  Phrase_2035_Is_Travel_Electric: 'c4e46adf-ac87-448b-a04b-266e185e1dcf',
  Phrase_Revenue_Growth_Achieved: 'f92ae541-2008-4427-b5d1-6fbc8bd34aa0',
  Phrase_Innovation_Market_Leader: '5af6782c-978b-447c-b849-2289f2bd2dc3',
  Phrase_Hardware_With_Own_Platform: '6a157996-89e7-4333-be1e-533b43347ddc',
  Phrase_Invest_In_Sustainable_Future: 'a5299a19-d94c-4b28-b9fa-28e740bc4aa6',
  Phrase_Target_Tenfold_In_Three_Years: '74fe97ff-08e3-465d-859d-f386b7a7a7b1',
  Phrase_Why_Invest: '05a9ac6c-1857-4e05-9434-4c6cf01da650',
  Phrase_More_Information_About_Investing: '84c09aeb-617f-429d-b6a4-02cb0072cbea',
  Phrase_Please_Contact_Us: '584302b9-2f1f-4c54-b084-8719f255d2f0',
  Phrase_Quick_Select: '70853fad-5987-4f03-b563-4e7948e4834a',
  Phrase_Schedule_Meeting: '117047ac-7a4b-4956-bb20-3ee29af0d017',
  // Company Data
  Invest_Email: '6cb9e900-4ce3-4524-bf75-b3d1d48b2804',
  Invest_Phone: 'c81bd4d8-a954-47e9-95d5-630610381a81',
  // Data
  Data_Amount: '898ba3f1-3c9b-4220-96d1-16864318df79',
  Data_Last_Update: '425a6a99-9c7f-45c3-bb43-756d2eae466a',
};

export default Id;
