import * as React from 'react';

import {
  Lang,
  Breakpoint,

  useDimensions,
  scrollToId,

  ContentContainer,
  Container,

  Button,
  Title,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/setup';

import WhyItem from '../Modules/WhyItem';

export default function Why(props) {
  const { _ } = Lang.useTranslation();
  const size = useDimensions();

  return (
    <>
      <div
        id="why"
      />
      <section
        className="SectionText"
        style={{
          marginTop: 150,
          marginBottom: size.width < Breakpoint.sm ? 100 : 0,
        }}
      >
        <ContentContainer>
          <Title
            textAlign="center"
            fontSize="2.5em"
            portableText
          >
            {props.title}
          </Title>
          <div
            style={{
              height: 'auto',
              width: '100%',
              marginBottom: 50,
              marginTop: size.width < Breakpoint.xxl ? 0 : 50,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: size.width < 870 ? 'column' : 'row',
              }}
            >
              <div>
                <Container
                  type="row"
                >
                  {
                    props.data.slice(0, 2).map((e, i) => (
                      <WhyItem
                        key={e.id}
                        data={e}
                        index={i}
                      />
                    ))
                  }
                </Container>
                <Container
                  type="row"
                >
                  {
                    props.data.slice(2, 4).map((e, i) => (
                      <WhyItem
                        key={e.id}
                        data={e}
                        index={i}
                      />
                    ))
                  }
                </Container>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: size.width >= Breakpoint.xs && size.width < 870 ? 'row' : 'column',
                  justifyContent: 'space-between',
                }}
              >
                {
                  props.data.slice(4, 6).map((e, i) => (
                    <WhyItem
                      key={e.id}
                      data={e}
                      index={i}
                    />
                  ))
                }
              </div>
            </div>
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                width: 'auto',
              }}
            >
              <Button
                id="Button_WhyReserveLink"
                text={_(Setup.RESERVE ? 'invest.reserveAShare' : 'invest.investNow', { ns: 'branding' })}
                colorScheme="green"
                onClick={() => {
                  scrollToId({
                    id: 'invest',
                    duration: 1500,
                  });
                }}
              />
            </div>
          </div>
        </ContentContainer>
      </section>
    </>
  );
}
