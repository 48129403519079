import * as React from 'react';
import {
  motion,
  AnimatePresence,
} from 'framer-motion';
import YouTube from 'react-youtube';

import {
  Colors,
  Lang,

  MainContainer,
  LargeContainer,

  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

export default function VideoOverlay(props) {
  const { _ } = Lang.useTranslation();

  const [hoverClose, setHoverClose] = React.useState(false);

  React.useEffect(() => {
    document.querySelector('body').style.overflow = props.visible ? 'hidden' : 'auto';
  });

  const opts = {
    height: '600',
    width: '100%',
    playerVars: {
      autoplay: 1,
    },
  };

  const onReady = (event) => {
    event.target.playVideo();
  };

  return (
    <AnimatePresence>
      {
        props.visible && (
          <>
            <motion.button
              type="button"
              id="Button_BackgroundVideo"
              onClick={props.onClose}
              className="Hover"
              onMouseEnter={() => {
                setHoverClose(true);
              }}
              onMouseLeave={() => {
                setHoverClose(false);
              }}
              style={{
                width: '100%',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 2147483004,
                border: 'solid 0px transparent',
              }}
              initial={{
                backgroundColor: 'rgba(55, 66, 74, 0)',
              }}
              animate={{
                backgroundColor: 'rgba(55, 66, 74, 0.9)',
              }}
              exit={{
                backgroundColor: 'rgba(55, 66, 74, 0)',
              }}
              transition={{
                duration: 0.5,
              }}
            >
              <MainContainer>
                <motion.div
                  onMouseEnter={() => {
                    setHoverClose(false);
                  }}
                  onMouseLeave={() => {
                    setHoverClose(true);
                  }}
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: 1,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  transition={{
                    delay: 0.3,
                  }}
                >
                  <YouTube
                    videoId={props.videoId}
                    opts={opts}
                    onReady={onReady}
                  />
                </motion.div>
              </MainContainer>
            </motion.button>
            <button
              type="button"
              id="Button_BackgroundVideoClose"
              onClick={props.onClose}
              className="Hover"
              onMouseEnter={() => {
                setHoverClose(true);
              }}
              onMouseLeave={() => {
                setHoverClose(false);
              }}
              style={{
                position: 'fixed',
                top: 0,
                zIndex: 2147483006,
                width: '100%',
                paddingTop: 30,
                border: 'solid 0px transparent',
                backgroundColor: 'transparent',
              }}
            >
              <LargeContainer>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <button
                    type="button"
                    id="Button_BackgroundVideoCloseInner"
                    onClick={props.onClose}
                    className="Hover"
                    style={{
                      border: 'solid 0px transparent',
                      backgroundColor: 'transparent',
                      fontSize: '1em',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Text
                      color={Colors.WHITE}
                    >
                      {_('close', { ns: 'ui' })}
                    </Text>
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        marginLeft: 7,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Icon
                        name={hoverClose ? 'ArrowRight' : 'Times'}
                        width={hoverClose ? 13 : 23}
                        height={hoverClose ? 13 : 23}
                        color={Colors.WHITE}
                        iconSet={hoverClose ? 'FA' : 'Feather'}
                      />
                    </div>
                  </button>
                </div>
              </LargeContainer>
            </button>
          </>
        )
      }
    </AnimatePresence>
  );
}
