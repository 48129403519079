import * as React from 'react';

import {
  Lang,

  ContentContainer,

  Title,
} from '@bluecurrent/web-component-lib';

import FaqItem from '../Sections/FaqItem';

export default function Faq(props) {
  const { _ } = Lang.useTranslation();

  const [active, setActive] = React.useState(false);

  return (
    <>
      <div
        id="faq"
      />
      <section
        className="SectionFaq"
        style={{
          marginTop: 100,
          position: 'relative',
          zIndex: 1,
        }}
      >
        <ContentContainer>
          <Title
            textAlign="center"
            fontSize="2.8em"
          >
            {_('faq', { ns: 'ui' })}
          </Title>
          <div
            style={{
              marginTop: 45,
            }}
          >
            {
              props.data.map((e, i) => (
                <div
                  /* eslint-disable-next-line no-underscore-dangle */
                  key={e._id}
                  style={{
                    marginBottom: (e.id + 1 === i) ? 0 : 25,
                  }}
                >
                  <FaqItem
                    data={e}
                    setActive={setActive}
                    active={active}
                  />
                </div>
              ))
            }
          </div>
        </ContentContainer>
      </section>
    </>
  );
}
