import * as React from 'react';

import {
  Colors,
  Breakpoint,
  Lang,

  useDimensions,

  Icon,
  Title,
  Confetti,

  ContentContainer,
  LargeContainer,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/setup';

import VideoOverlay from '../Sections/VideoOverlay';

export default function Video(props) {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();

  const [video, setVideo] = React.useState(false);
  const [width, setWidth] = React.useState('100%');

  const contentContainerRef = React.useRef(0);

  const getWidth = () => {
    if (contentContainerRef.current.clientWidth !== null) {
      setWidth(contentContainerRef.current.clientWidth);
    }
  };

  React.useEffect(() => {
    getWidth();
  }, [width]);

  React.useEffect(() => {
    window.addEventListener('resize', getWidth);

    return () => {
      window.removeEventListener('resize', getWidth);
    };
  }, []);

  return (
    <>
      <LargeContainer>
        <div
          id="video"
          style={{
            marginTop: 150,
            height: 'auto',
            width: '100%',
            background: Setup.SUCCESS ? `linear-gradient(0deg, rgba(255,255,255,0) 0%, ${Colors.GRADIENT} 50%)` : Colors.GRADIENT,
            borderTopLeftRadius: size.width < Breakpoint.xxl ? 0 : 10,
            borderTopRightRadius: size.width < Breakpoint.xxl ? 0 : 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: 25,
            position: 'relative',
            zIndex: 1,
          }}
        >
          <section
            className="SectionVideo"
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width,
                height: 'auto',
              }}
            >
              <div
                style={{
                  paddingTop: 50,
                  paddingBottom: 30,
                  position: 'relative',
                  zIndex: 2,
                }}
              >
                <Title
                  fontSize="2.5em"
                  textAlign="center"
                  portableText
                >
                  {props.title}
                </Title>
              </div>
              <button
                className="Hover"
                id="Button_VideoBackground"
                type="button"
                onClick={() => {
                  setVideo(!video);
                }}
                aria-label={`${_('watchVideo', { ns: 'ui' })} ${_('accessibility.button', { ns: 'ui' }).toLowerCase()}`}
                style={{
                  width: '100%',
                  height: 650,
                  backgroundColor: Colors.MEDIUM_WHITE,
                  borderRadius: 15,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 'solid 0px transparent',
                  backgroundImage: 'url(\'https://cdn.sanity.io/images/pnajo2cq/production/19a2ea2de975382305da518be527caa0250e6b90-1000x800.jpg?&fit=crop&auto=format&q=100\')',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'none',
                  backgroundPosition: 'center',
                  position: 'relative',
                  zIndex: 2,
                  overflow: 'hidden',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    height: '100%',
                    width: '100%',
                    opacity: 0.5,
                  }}
                >
                  <Confetti
                    type="DotsPath"
                    height={1000}
                    width={1000}
                    color={Colors.WHITE}
                    transform="scaleX(-1)"
                    position="absolute"
                    right={-200}
                    top={50}
                  />
                </div>
                <Icon
                  name="Play"
                  height={50}
                  width={50}
                  color={Colors.WHITE}
                  iconSet="FA"
                />
              </button>
            </div>
            <div
              id="pitchdeck"
            />
          </section>
        </div>
      </LargeContainer>
      <ContentContainer>
        <div
          ref={contentContainerRef}
          style={{
            width: '100%',
            height: 0,
          }}
        />
      </ContentContainer>
      <VideoOverlay
        videoId="4K5WA5wljNQ"
        visible={video}
        onClose={() => {
          setVideo(!video);
        }}
      />
    </>
  );
}
