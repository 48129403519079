import * as React from 'react';

import {
  Colors,
  Breakpoint,

  useDimensions,

  Text,
  CustomIcon,
} from '@bluecurrent/web-component-lib';

export default function WhyItem(props) {
  const size = useDimensions();

  return (
    <div
      style={{
        height: 'auto',
        width: '100%',
        paddingLeft: size.width > Breakpoint.xxl && props.index === 0 ? 0 : 30,
        paddingRight: size.width > Breakpoint.xxl && props.index === 3 ? 0 : 30,
        marginTop: size.width <= Breakpoint.xxl && (props.index === 1 || props.index === 0)
          ? 30
          : 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          height: 100,
          width: 100,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CustomIcon
          name={props.data.icon.name}
          width={props.data.icon.width ?? 100}
          height={props.data.icon.height ?? 100}
          color={props.data.icon.color ?? Colors.GREY}
          colorBattery={(props.data.icon.name === 'Battery' && props.data.icon.colorBattery) ?? Colors.GREY}
        />
      </div>
      <div
        style={{
          paddingTop: 15,
        }}
      >
        <Text
          fontWeight={500}
          fontSize="1.4em"
          portableText={props.data.title.portableText}
          textAlign="center"
        >
          {props.data.title.content}
        </Text>
      </div>
      <div
        style={{
          paddingTop: 20,
        }}
      >
        <Text
          portableText={props.data.text.portableText}
          textAlign="center"
        >
          {props.data.text.content}
        </Text>
      </div>
    </div>
  );
}
