import * as React from 'react';
import axios from 'axios';
import Script from 'next/script';

import {
  blockContentToPlainText,
} from 'react-portable-text';

import {
  Lang,
  Breakpoint,
  Colors,

  useDimensions,
  currencyFormat,

  Input,
  Button,
  Text,
  Icon,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/setup';

import ByPressingYouAcceptText from '../Modules/ByPressingYouAcceptText';
import FormSuccessMessage from './FormSuccessMessage';

export default function Form(props) {
  const { _, i18n } = Lang.useTranslation();
  const size = useDimensions();

  // const minAmount = 500;
  const minAmountEmployee = 250;
  const maxAmount = 5000000;
  const defaultAmount = 0;
  const step = 10;

  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [amount, setAmount] = React.useState(defaultAmount);

  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [success, setSuccess] = React.useState(false);

  const quickSelect = [
    {
      id: 0,
      amount: 1000,
    },
    {
      id: 1,
      amount: 5000,
    },
    {
      id: 2,
      amount: 25000,
    },
    {
      id: 3,
      amount: 100000,
    },
  ];

  const onSubmit = (event) => {
    event.preventDefault();

    setErrors([]);
    setLoading(true);

    window.grecaptcha.ready(() => {
      try {
        window.grecaptcha.execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, { action: 'submit' }).then((token) => {
          axios({
            method: 'post',
            url: Setup.RESERVE ? '/api/form' : Setup.SUCCESS ? 'api/sign-up' : '/api/invest',
            data: {
              firstname: firstname.charAt(0).toUpperCase() + firstname.slice(1),
              lastname: lastname.charAt(0).toUpperCase() + lastname.slice(1),
              email: email.toLowerCase(),
              phone,
              language: i18n.language,
              /* eslint-disable-next-line radix */
              amount: Number.parseInt(amount),
              'g-recaptcha-response': token,
            },
          })
            .then(() => {
              setFirstname('');
              setLastname('');
              setEmail('');
              setPhone('');
              setAmount(defaultAmount);
              setLoading(false);
              setSuccess(true);
            })
            .catch(({ response }) => {
              switch (response.status) {
                case 400:
                  if (response.data.message) {
                    setErrorMessage(response.data.message);
                  } else if (response.data.errors) {
                    setErrors(response.data.errors);
                  }
                  break;
                case 500:
                  setErrorMessage(_('error.somethingWrong', { ns: 'chargepoint' }));
                  break;
                default:
                  setErrorMessage(_('error.somethingWrong', { ns: 'chargepoint' }));
              }
              setLoading(false);
            });
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
        setErrorMessage(_('error.somethingWrong', { ns: 'chargepoint' }));
      }
    });
  };

  const error = (field) => {
    const errorKey = errors.find((err) => err.field === field)?.error;
    if (!errorKey) return false;
    return _(`errors.${errorKey}`, { ns: 'ui' });
  };

  return (
    <form
      method="post"
      style={{
        width: '100%',
        height: 'auto',
      }}
      onSubmit={(event) => {
        if (Setup.SUCCESS) event.preventDefault();
      }}
    >
      <Script
        src={`https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_KEY}`}
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: size.width < Breakpoint.sm ? 'column' : 'row',
        }}
      >
        <div
          style={{
            marginRight: size.width < Breakpoint.sm ? 0 : 7.5,
            width: '100%',
          }}
        >
          <Input
            type="text"
            id="Form_FirstName"
            name="firstName"
            label={_('firstName', { ns: 'settings' })}
            required
            styling
            onChange={(event) => setFirstname(event.target.value)}
            value={firstname}
            error={error('firstname')}
            autocomplete="given-name"
            ariaLabel={`${_('firstName', { ns: 'settings' })} ${_('accessibility.input', { ns: 'ui' }).toLowerCase()}`}
          />
        </div>
        <div
          style={{
            marginTop: size.width < Breakpoint.sm ? 15 : 0,
            marginLeft: size.width < Breakpoint.sm ? 0 : 7.5,
            width: '100%',
          }}
        >
          <Input
            type="text"
            id="Form_LastName"
            name="lastName"
            label={_('lastName', { ns: 'settings' })}
            required
            styling
            onChange={(event) => setLastname(event.target.value)}
            value={lastname}
            error={error('lastname')}
            autocomplete="family-name"
            ariaLabel={`${_('lastName', { ns: 'settings' })} ${_('accessibility.input', { ns: 'ui' }).toLowerCase()}`}
          />
        </div>
      </div>

      <div
        style={{
          marginTop: 15,
        }}
      >
        <Input
          type="text"
          id="Form_Email"
          name="email"
          label={_('email', { ns: 'authentication' })}
          required
          styling
          onChange={(event) => setEmail(event.target.value)}
          value={email}
          error={error('email')}
          autocomplete="email"
          ariaLabel={`${_('email', { ns: 'authentication' })} ${_('accessibility.input', { ns: 'ui' }).toLowerCase()}`}
        />
      </div>

      {
        !Setup.SUCCESS && (
          <div
            style={{
              marginTop: 15,
            }}
          >
            <Input
              type="text"
              id="Form_Phone"
              name="tel"
              label={_('phone', { ns: 'authentication' })}
              required
              styling
              onChange={(event) => setPhone(event.target.value)}
              value={phone}
              error={error('phone')}
              autocomplete="tel"
              ariaLabel={`${_('phone', { ns: 'authentication' })} ${_('accessibility.input', { ns: 'ui' }).toLowerCase()}`}
              disabled={Setup.SUCCESS}
            />
          </div>
        )
      }
      {
        !Setup.SUCCESS && (
          <div
            style={{
              marginTop: 20,
            }}
          >
            <Input
              label={`${_('amount', { ns: 'ui' })} ${_('currency.inEuro', { ns: 'ui' })}`}
              id="Form_Amount"
              type="number"
              name={amount}
              min={minAmountEmployee}
              max={maxAmount}
              step={step}
              value={amount}
              onChange={(event) => {
                setAmount(event.target.value);
              }}
              error={
                (amount >= 1 && amount < minAmountEmployee)
                  || amount % 10 !== 0
                  || amount > maxAmount
              }
              required
              styling
              ariaLabel={`${_('amount', { ns: 'ui' })} ${_('currency.inEuro', { ns: 'ui' })} ${_('accessibility.input', { ns: 'ui' }).toLowerCase()}`}
              disabled={Setup.SUCCESS}
            />
            <div
              style={{
                marginTop: 20,
              }}
            >
              <Text
                fontWeight={500}
                fontSize="1.1em"
                color={Colors.GREY}
                portableText
              >
                {props.quickSelectText}
              </Text>
            </div>
            <div
              style={{
                marginTop: 10,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
              }}
            >
              {
                quickSelect.map((e) => (
                  <div
                    key={e.id}
                    style={{
                      width: 'auto',
                      marginRight: e.id + 1 === quickSelect.length ? 0 : 15,
                      marginBottom: 15,
                    }}
                  >
                    <Button
                      className="Button_QuickSelect"
                      text={currencyFormat(e.amount, 'nl', 'EUR')}
                      colorScheme="white"
                      onClick={() => {
                        setAmount(e.amount);
                      }}
                      ariaLabel={`${blockContentToPlainText(props.quickSelectText)}: ${currencyFormat(e.amount, 'nl', 'EUR')}`}
                      disabled={Setup.SUCCESS}
                    />
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
      {
        errorMessage && (
          <div
            style={{
              marginTop: 70,
              height: 'auto',
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginBottom: 15,
            }}
          >
            <div
              style={{
                height: 21,
                width: 21,
                marginRight: 7,
              }}
            >
              <Icon
                name="ExclamationCircle"
                height={21}
                width={21}
                color={Colors.RED}
                iconSet="FA"
              />
            </div>
            <Text
              color={Colors.RED}
              fontWeight={100}
            >
              {errorMessage}
            </Text>
          </div>
        )
      }
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            width: size.width < Breakpoint.xs ? '100%' : 'auto',
            marginTop: errorMessage || (!Setup.SUCCESS && success) ? 0 : 70,
          }}
        >
          {
            Setup.SUCCESS ? (
              <Button
                id="Form_ButtonSignUp"
                type="submit"
                text={_('signUp', { ns: 'ui' })}
                colorScheme={
                  firstname === ''
                  || lastname === ''
                  || email === ''
                    ? 'grey'
                    : 'green'
                }
                onClick={(event) => {
                  onSubmit(event);
                }}
                loading={loading}
                disabled={
                  firstname === ''
                  || lastname === ''
                  || email === ''
                }
                ariaLabel={`${_('signUp', { ns: 'ui' })} ${_('accessibility.button', { ns: 'ui' }).toLowerCase()}`}
              />
            ) : (
              <Button
                id="Form_ButtonSubmit"
                type="submit"
                text={_(Setup.RESERVE ? 'invest.reserve' : 'invest.invest', { ns: 'branding' })}
                colorScheme={
                  amount < minAmountEmployee
                  || amount % 10 !== 0
                  || amount > maxAmount
                  || firstname === ''
                  || lastname === ''
                  || email === ''
                  || phone === ''
                    ? 'grey'
                    : 'green'
                }
                onClick={(event) => {
                  if (!Setup.SUCCESS) onSubmit(event);
                }}
                loading={loading}
                disabled={
                  amount < minAmountEmployee
                  || amount % 10 !== 0
                  || amount > maxAmount
                  || firstname === ''
                  || lastname === ''
                  || email === ''
                  || phone === ''
                }
                ariaLabel={`${_(Setup.RESERVE ? 'invest.reserve' : 'invest.invest', { ns: 'branding' })} ${_('accessibility.button', { ns: 'ui' }).toLowerCase()}`}
              />
            )
          }
        </div>
      </div>
      <ByPressingYouAcceptText
        button={
          _(
            Setup.RESERVE
              ? 'invest.reserve'
              : Setup.SUCCESS
                ? 'signUp'
                : 'invest.invest',
            {
              ns: Setup.SUCCESS ? 'ui' : 'branding',
            },
          )
        }
        link={_('privacyPolicy', { ns: 'legal' })}
        href="https://www.bluecurrent.nl/legal/privacy-statement"
      />
      {
        success && (
          <FormSuccessMessage
            visible={success}
            onClose={() => setSuccess(false)}
            content={props.successContent}
          />
        )
      }
    </form>
  );
}
