import * as React from 'react';

import {
  blockContentToPlainText,
} from 'react-portable-text';

import {
  Breakpoint,
  Lang,

  MainContainer,

  useDimensions,

  Button,
  Text,
  ContactCard,
} from '@bluecurrent/web-component-lib';

export default function Contact(props) {
  const size = useDimensions();
  const { _ } = Lang.useTranslation();

  return (
    <section
      className="SectionContact"
      style={{
        marginTop: 70,
        position: 'relative',
        zIndex: 0,
      }}
    >
      <MainContainer>
        <ContactCard
          src="https://cdn.sanity.io/images/pnajo2cq/production/14bd0b3eaeefc71211b408e28aed576a8e15807d-1100x1000.jpg?w=500&h=500&fit=fill&q=100&crop=center&auto=format"
          text={{
            topLeft: props.topLeft,
            bottomLeft: props.bottomLeft,
            topRight: props.topRight,
            bottomRight: props.bottomRight,
          }}
        />
        {
          props.buttons && (
            <>
              <div
                style={{
                  marginTop: size.width < Breakpoint.md ? 20 : 0,
                  marginBottom: 20,
                }}
              >
                <Text
                  textAlign="center"
                >
                  {_('or', { ns: 'ui' })}
                </Text>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                {
                  props.buttons.map((e) => (
                    <div
                      key={e.id}
                      style={{
                        width: 'auto',
                      }}
                    >
                      <Button
                        text={blockContentToPlainText(e.text)}
                        colorScheme={e.colorScheme}
                        onClick={e.onClick}
                      />
                    </div>
                  ))
                }
              </div>
            </>
          )
        }
      </MainContainer>
    </section>
  );
}
