import * as React from 'react';

import {
  Colors,
  Breakpoint,
  Lang,

  useDimensions,
  scrollToId,

  ContentContainer,
  Container,

  TextBlock,
  Image,
  Confetti,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/setup';

export default function How(props) {
  const { _, i18n } = Lang.useTranslation();
  const size = useDimensions();

  const lang = Setup.PSEUDO ? 'en' : i18n.language;

  return (
    <>
      <section
        className="SectionText"
        style={{
          marginTop: size.width < Breakpoint.sm ? 100 : 150,
          width: '100%',
          position: 'relative',
        }}
      >
        <ContentContainer>
          <Container
            share={[50, 50]}
            breakpoint="sm"
            invert={size.width >= Breakpoint.sm}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                paddingRight: size.width < Breakpoint.sm ? 0 : 7.5,
              }}
            >
              <Image
                src="https://cdn.sanity.io/images/pnajo2cq/production/8d96383e791f81ce79fe6ae4452fb572373739c5-1000x800.jpg?fit=crop&auto=format&q=100"
                confetti={{
                  type: 'Stripes',
                  height: 150,
                  width: 150,
                  color: Colors.PURPLE,
                  position: 'absolute',
                  right: -30,
                  top: 100,
                }}
                wrapper="Figure"
                id="HowImage1"
              />
            </div>
            <div
              style={{
                height: '100%',
                width: (size.width >= Breakpoint.lg && size.width < Breakpoint.xxl) ? '80%' : '100%',
                paddingLeft: size.width < Breakpoint.sm ? 0 : 7.5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                marginTop: size.width < Breakpoint.sm ? 15 : 0,
              }}
            >
              <TextBlock
                title={{
                  content: props.data[0].content.title[lang],
                }}
                text={{
                  content: props.data[0].content.text[lang],
                  portableText: true,
                }}
                align="left"
              />
            </div>
          </Container>
        </ContentContainer>
      </section>
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          zIndex: -1,
          marginTop: -420,
          overflow: 'hidden',
        }}
      >
        <Confetti
          type="DotsPath"
          height={1000}
          width={1000}
          position="absolute"
          color={Colors.MEDIUM_WHITE}
          top={0}
          left={
            size.width < 1630
              ? size.width < 1260
                ? size.width < 930
                  ? -100
                  : 0
                : 150
              : 330
          }
          transform="scaleX(-1) rotate(15deg)"
        />
      </div>
      <section
        className="SectionText"
        style={{
          marginTop: size.width < Breakpoint.sm ? 100 : 150,
        }}
      >
        <ContentContainer>
          <Container
            share={[50, 50]}
            breakpoint="sm"
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                paddingRight: size.width < Breakpoint.sm ? 0 : 30,
              }}
            >
              <Image
                src="https://cdn.sanity.io/images/pnajo2cq/production/4dbf41a8912bc2a4db5c69b70c84e3742387eed6-1000x800.jpg?fit=crop&auto=format&q=100"
                minHeight={450}
              />
            </div>
            <div
              style={{
                height: '100%',
                width: (size.width >= Breakpoint.lg && size.width < Breakpoint.xxl) ? '80%' : '100%',
                paddingLeft: size.width < Breakpoint.sm ? 0 : 7.5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <TextBlock
                title={{
                  content: props.data[1].content.title[lang],
                }}
                text={{
                  content: props.data[1].content.text[lang],
                  portableText: true,
                }}
                align="left"
                buttons={[
                  {
                    id: 0,
                    customId: 'Button_WatchVideoLink',
                    text: _('invest.watchVideo', { ns: 'branding' }),
                    onClick: () => {
                      scrollToId({
                        id: 'video',
                        duration: 1500,
                      });
                    },
                  },
                ]}
              />
            </div>
          </Container>
        </ContentContainer>
      </section>
      {
        size.width >= Breakpoint.sm && (
          <div
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
              zIndex: -1,
              marginTop: size.width < 1630
                ? size.width < Breakpoint.xxl
                  ? size.width < 1050
                    ? -150
                    : -250
                  : -150
                : -250,
              overflow: 'hidden',
            }}
          >
            <Confetti
              type="DotsPath"
              height={1000}
              width={1000}
              position="absolute"
              color={Colors.MEDIUM_WHITE}
              top={0}
              left={
                size.width < 1630
                  ? size.width < Breakpoint.xxl
                    ? 240
                    : 600
                  : 550
              }
              transform="rotate(15deg)"
            />
          </div>
        )
      }
      <section
        className="SectionText"
        style={{
          marginTop: size.width < Breakpoint.sm ? 100 : 150,
        }}
      >
        <ContentContainer>
          <Container
            share={[50, 50]}
            breakpoint="sm"
            invert={size.width >= Breakpoint.sm}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                paddingRight: size.width < Breakpoint.sm ? 0 : 7.5,
              }}
            >
              <Image
                src="https://cdn.sanity.io/images/pnajo2cq/production/fc6a9b18ab7535299c484e1be9675df26f510dec-1000x800.jpg?fit=crop&auto=format&q=100"
                confetti={{
                  type: 'C',
                  height: 160,
                  width: 160,
                  color: Colors.GREEN,
                  position: 'absolute',
                  right: 25,
                  bottom: 0,
                }}
                wrapper="Figure"
                id="HowImage3"
              />
            </div>
            <div
              style={{
                height: '100%',
                width: (size.width >= Breakpoint.lg && size.width < Breakpoint.xxl) ? '80%' : '100%',
                paddingLeft: size.width < Breakpoint.sm ? 0 : 7.5,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <TextBlock
                title={{
                  content: props.data[2].content.title[lang],
                }}
                text={{
                  content: props.data[2].content.text[lang],
                  portableText: true,
                }}
                align="left"
              />
            </div>
          </Container>
        </ContentContainer>
      </section>
    </>
  );
}
