import * as React from 'react';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';
import axios from 'axios';

import {
  Text,
  Lang,
  Breakpoint,
  Colors,

  useDimensions,

  LargeContainer,
  ContentContainer,

  TextBlock,
  Input,
  Button,
  Icon,
} from '@bluecurrent/web-component-lib';

import Setup from '../../config/setup';

import ByPressingYouAcceptText from '../Modules/ByPressingYouAcceptText';

import FormSuccessMessage from '../Sections/FormSuccessMessage';

export default function DownloadPlan(props) {
  const { _, i18n } = Lang.useTranslation();
  const size = useDimensions();

  const lang = Setup.PSEUDO ? 'en' : i18n.language;

  const [errorMessage, setErrorMessage] = React.useState(null);
  const [errors, setErrors] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const [emailOpen, setEmailOpen] = React.useState(false);
  const [firstname, setFirstname] = React.useState('');
  const [email, setEmail] = React.useState('');

  const [width, setWidth] = React.useState('100%');

  const contentContainerRef = React.useRef(0);

  const getWidth = () => {
    if (contentContainerRef.current.clientWidth !== null) {
      setWidth(contentContainerRef.current.clientWidth);
    }
  };

  React.useEffect(() => {
    getWidth();
  }, [width]);

  React.useEffect(() => {
    window.addEventListener('resize', getWidth);

    return () => {
      window.removeEventListener('resize', getWidth);
    };
  }, []);

  const submit = (event) => {
    event.preventDefault();
    setLoading(true);
    setErrors([]);
    setErrorMessage(null);
    window.grecaptcha.ready(() => {
      try {
        window.grecaptcha.execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, { action: 'submit' }).then((token) => {
          axios({
            method: 'post',
            url: '/api/pitchdeck',
            data: {
              firstname: firstname.charAt(0).toUpperCase() + firstname.slice(1),
              email: email.toLowerCase(),
              language: i18n.language,
              'g-recaptcha-response': token,
            },
          })
            .then(() => {
              setFirstname('');
              setEmail('');
              setLoading(false);
              setSuccess(true);
            })
            .catch(({ response }) => {
              switch (response.status) {
                case 400:
                  if (response.data.message) {
                    setErrorMessage(response.data.message);
                  } else if (response.data.errors) {
                    setErrors(response.data.errors);
                  }
                  break;
                case 500:
                  setErrorMessage(_('error.somethingWrong', { ns: 'chargepoint' }));
                  break;
                default:
              }
              setLoading(false);
            });
        });
      } catch (error) {
        console.log(error);
        setLoading(false);
        setErrorMessage(_('error.somethingWrong', { ns: 'chargepoint' }));
      }
    });
  };

  const error = (field) => {
    const errorKey = errors.find((err) => err.field === field)?.error;
    if (!errorKey) return false;
    return _(`errors.${errorKey}`, { ns: 'ui' });
  };

  return (
    <>
      <LargeContainer>
        <div
          style={{
            width: '100%',
            background: `linear-gradient(0deg, rgba(255,255,255,0) 0%, ${Colors.GRADIENT} 50%)`,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            borderBottomLeftRadius: size.width < Breakpoint.xxl ? 0 : 10,
            borderBottomRightRadius: size.width < Breakpoint.xxl ? 0 : 10,
          }}
        >
          <div
            style={{
              width,
              height: 'auto',
            }}
          >
            <div
              id="pitch-deck"
            />
            <section
              className="SectionDownloadPlan"
              style={{
                marginTop: 50,
                marginBottom: 50,
                position: 'relative',
                zIndex: 0,
              }}
            >
              <TextBlock
                title={{
                  content: props.content.title[lang],
                }}
                text={{
                  content: props.content.text[lang],
                  portableText: true,
                }}
                align="center"
              />
              <form
                style={{
                  width: size.width < Breakpoint.sm ? '100%' : '60%',
                  marginLeft: size.width < Breakpoint.sm ? 0 : '20%',
                  marginRight: size.width < Breakpoint.sm ? 0 : '20%',
                }}
              >
                <Input
                  type="text"
                  id="DownloadPlan_FirstName"
                  label={_('firstName', { ns: 'settings' })}
                  name="firstName"
                  required
                  styling
                  autocomplete="given-name"
                  value={firstname}
                  onChange={(e) => {
                    setFirstname(e.target.value);
                    setEmailOpen(true);
                  }}
                  error={error('firstname')}
                  ariaLabel={`${_('firstName', { ns: 'settings' })} ${_('accessibility.input', { ns: 'ui' }).toLowerCase()}`}
                />
                <AnimatePresence>
                  {
                    emailOpen && (
                      <motion.div
                        initial={{
                          height: 0,
                          opacity: 0,
                          marginTop: 0,
                        }}
                        animate={{
                          height: 'auto',
                          opacity: 1,
                          marginTop: 15,
                        }}
                        exit={{
                          height: 0,
                          opacity: 0,
                          marginTop: 0,
                        }}
                        transition={{
                          ease: 'linear',
                        }}
                      >
                        <Input
                          type="text"
                          id="DownloadPlan_Email"
                          label={_('email', { ns: 'authentication' })}
                          name="email"
                          required
                          styling
                          autocomplete="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          error={error('email')}
                          ariaLabel={`${_('email', { ns: 'authentication' })} ${_('accessibility.input', { ns: 'ui' }).toLowerCase()}`}
                        />
                      </motion.div>
                    )
                  }
                </AnimatePresence>
                {
                  errorMessage && (
                    <div
                      style={{
                        marginTop: 10,
                        height: 'auto',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          height: 21,
                          width: 21,
                          marginRight: 7,
                        }}
                      >
                        <Icon
                          name="ExclamationCircle"
                          height={21}
                          width={21}
                          color={Colors.RED}
                          iconSet="FA"
                        />
                      </div>
                      <Text
                        color={Colors.RED}
                        fontWeight={100}
                      >
                        {errorMessage}
                      </Text>
                    </div>
                  )
                }
                <div
                  style={{
                    width: '100%',
                    marginTop: 20,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      width: 'auto',
                    }}
                  >
                    <Button
                      id="Button_DownloadPlanSubmit"
                      type="submit"
                      text={_('download', { ns: 'ui' })}
                      colorScheme={
                        firstname === ''
                        || email === ''
                          ? 'grey'
                          : 'blue'
                      }
                      onClick={(event) => {
                        submit(event);
                      }}
                      loading={loading}
                      disabled={
                        firstname === ''
                        || email === ''
                      }
                      ariaLabel={`${_('download', { ns: 'ui' })} ${_('accessibility.button', { ns: 'ui' }).toLowerCase()}`}
                    />
                  </div>
                </div>
                <ByPressingYouAcceptText
                  button={_('download', { ns: 'ui' })}
                  link={_('privacyPolicy', { ns: 'legal' })}
                  href="https://www.bluecurrent.nl/legal/privacy-statement"
                />
              </form>
            </section>
          </div>
        </div>
      </LargeContainer>
      <ContentContainer>
        <div
          ref={contentContainerRef}
          style={{
            width: '100%',
            height: 0,
          }}
        />
      </ContentContainer>
      {
        success && (
          <FormSuccessMessage
            visible={success}
            onClose={() => setSuccess(false)}
            content={props.successContent}
          />
        )
      }
    </>
  );
}
